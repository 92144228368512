//file: index.js
//language: javascript (gatsby)
//Description: this is the page source code
//Happy Love EBook Guide

//date create : 02/04/2020


// this import library for gatsby and react 
import React from "react"
import { Helmet } from "react-helmet"
import lazy from '@loadable/component'


//import App from "./Appbook"


const SectioOne = lazy(() => import('../components/section/free/secOne'))
const SectioTwo = lazy(() => import('../components/section/free/secTwo'))
const SectioTheree = lazy(() => import('../components/section/free/secTheree'))
const SectioFour = lazy(() => import('../components/section/free/secFour'))
const SectioFive = lazy(() => import('../components/section/free/secFive'))
const SectioSix = lazy(() => import('../components/section/free/secSix'))
const SectioSeven = lazy(() => import('../components/section/free/secSeven'))
const SectioEight = lazy(() => import('../components/section/free/secEight'))
const SectioTos = lazy(() => import('../components/section/free/tos'))

// function class {Aplication}, for front page
class ApplicationEbook extends React.Component {
 
  render() {
    return (
      <React.Fragment>
        {/* this is title website */}
        <div className="application">
          <Helmet>
            <meta charSet="utf-8" />
            <title>E-Book Happy Love Guide</title>
          </Helmet>
        </div>
        {/* end title website */}
          <SectioOne></SectioOne>
          <SectioTwo></SectioTwo>
          <SectioTheree></SectioTheree>
          <SectioFour></SectioFour>
          <SectioFive></SectioFive>
          <SectioSix></SectioSix>
          <SectioSeven></SectioSeven>
          <SectioEight></SectioEight>
          <SectioTos></SectioTos>
      </React.Fragment>
    )
  }
}
export default ApplicationEbook